import {
  Alert,
  Button,
  FileInput,
  Modal,
  NumberInput,
  Space,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import React, { useState } from "react";
import styled from "styled-components";
import { uploadFile } from "../../api/upload";
import { createProduct } from "../../api/product";
import { IconAlertCircle } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

export const CreateProductPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sizeModal, setSizeModal] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (error) setError(null);
    const name = e.target.elements.name.value;
    const description = e.target.elements.description.value;
    const price = e.target.elements.price.value;
    const imageFile = e.target.elements.image.files[0];
    if (imageFile.size > 1000000) {
      setSizeModal(true);
      setLoading(false);
      return;
    }
    uploadFile(imageFile)
      .then((data) => {
        return createProduct({
          name,
          description,
          price,
          images: [data.filename],
        });
      })
      .then(() => {
        navigate("/products");
      })
      .catch((err) => {
        console.error(err);
        setError("Произошла ошибка, повторите еще раз");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Text size={18}>Создать товар</Text>
      <Space h="md" />
      <Form onSubmit={handleSubmit}>
        <TextInput name="name" label="Название" required />
        <Space h="md" />
        <Textarea name="description" label="Описание" required />
        <Space h="md" />
        <NumberInput name="price" label="Цена" required icon={<Text>₽</Text>} />
        <Space h="md" />
        <FileInput
          name="image"
          label="Изображение"
          accept="image/png,image/jpeg"
          required
          multiple={false}
        />
        <Space h="md" />
        {error ? (
          <>
            <Alert icon={<IconAlertCircle size="1rem" />} color="red">
              {error}
            </Alert>
            <Space h="md" />
          </>
        ) : null}
        <Button loading={loading} type="submit">
          Добавить
        </Button>
      </Form>
      <Modal
        title="Слишком большой размер изображения"
        opened={sizeModal}
        onClose={() => setSizeModal(false)}
      >
        <Text>Загрузите файл размером меньше 1 мегабайта.</Text>
        <Text>
          Можно воспользоваться этим сайтом{" "}
          <a
            href="https://imagecompressor.com/"
            target="_blank"
            rel="noreferrer"
          >
            https://imagecompressor.com/
          </a>
          , чтобы уменьшить размер файла.
        </Text>
      </Modal>
    </div>
  );
};

const Form = styled.form`
  max-width: 500px;
`;
