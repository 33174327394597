import axios from "axios";

export const getUser = () => {
  return axios.get("/admin").then((res) => {
    return {
      id: res?.data?.admin?.id,
      email: res?.data?.admin?.email,
    };
  });
};

export const loginUser = ({ email, password }) => {
  return axios.post("/admin/login", { email, password }).then((res) => {
    return res.data;
  });
};
