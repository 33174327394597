import {
  Alert,
  Button,
  Center,
  FileInput,
  Group,
  Loader,
  Modal,
  Space,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { uploadFile } from "../../api/upload";
import { deleteRecipe, getRecipe, updateRecipe } from "../../api/recipe";

export const UpdateRecipePage = () => {
  const params = useParams();

  const [deleting, setDeleting] = useState(false);
  const [deleteOpened, setDeleteOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sizeModal, setSizeModal] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(undefined);
  const [defaultImage, setDefaultImage] = useState(undefined);

  const navigate = useNavigate();

  useEffect(() => {
    setPageLoading(true);
    getRecipe(params.recipeId)
      .then((data) => {
        setTitle(data.recipe.title);
        setDescription(data.recipe.description);
        setDefaultImage(data.recipe.images[0]);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setPageLoading(false);
      });
  }, [params.recipeId]);

  const handleDelete = () => {
    setDeleting(true);
    deleteRecipe(params.recipeId)
      .then(() => {
        navigate("/recipes");
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let imageName;
    if (image) {
      if (image.size > 1000000) {
        setSizeModal(true);
        setLoading(false);
        return;
      }
      try {
        const uploadedFile = await uploadFile(image);
        imageName = uploadedFile.filename;
      } catch (err) {
        console.error(err);
        setError("Произошла ошибка, повторите еще раз");
        setLoading(false);
        return;
      }
    } else {
      imageName = defaultImage;
    }
    const data = {
      title,
      description,
      images: [imageName],
    };
    updateRecipe(params.recipeId, data)
      .then(() => {
        navigate("/recipes");
      })
      .catch((err) => {
        console.error(err);
        setError("Произошла ошибка, повторите еще раз");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Text size={18}>Изменить рецепт</Text>
      <Space h="md" />
      {pageLoading ? (
        <Center>
          <Space h={100} />
          <Loader />
        </Center>
      ) : (
        <Form onSubmit={handleSubmit}>
          <TextInput
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            label="Название"
            required
          />
          <Space h="md" />
          <Textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            name="description"
            label="Описание"
            autosize
            required
          />
          <Space h="md" />
          <FileInput
            name="image"
            label="Изображение"
            accept="image/png,image/jpeg"
            multiple={false}
            value={image}
            onChange={(file) => {
              setDefaultImage(undefined);
              setImage(file);
            }}
          />
          {defaultImage ? (
            <>
              <Space h="md" />
              <Image src={`/uploads/${defaultImage}`} alt={defaultImage} />
            </>
          ) : null}
          <Space h="md" />
          {error ? (
            <>
              <Alert icon={<IconAlertCircle size="1rem" />} color="red">
                {error}
              </Alert>
              <Space h="md" />
            </>
          ) : null}
          <Group spacing="md">
            <Button loading={loading} disabled={deleting} type="submit">
              Изменить
            </Button>
            <Button
              loading={deleting}
              disabled={loading}
              type="button"
              variant="outline"
              color="red"
              onClick={() => setDeleteOpened(true)}
            >
              Удалить
            </Button>
          </Group>
        </Form>
      )}
      <Modal
        opened={deleteOpened}
        onClose={() => setDeleteOpened(false)}
        title="Точно удалить рецепт?"
        centered
      >
        <Group>
          <Button
            loading={deleting}
            onClick={handleDelete}
            size="sm"
            color="red"
          >
            Удалить
          </Button>
          <Button
            size="sm"
            onClick={() => setDeleteOpened(false)}
            variant="outline"
          >
            Закрыть
          </Button>
        </Group>
      </Modal>
      <Modal
        title="Слишком большой размер изображения"
        opened={sizeModal}
        onClose={() => setSizeModal(false)}
      >
        <Text>Загрузите файл размером меньше 1 мегабайта.</Text>
        <Text>
          Можно воспользоваться этим сайтом{" "}
          <a
            href="https://imagecompressor.com/"
            target="_blank"
            rel="noreferrer"
          >
            https://imagecompressor.com/
          </a>
          , чтобы уменьшить размер файла.
        </Text>
      </Modal>
    </div>
  );
};

const Form = styled.form`
  max-width: 500px;
`;

const Image = styled.img`
  height: 150px;
`;
