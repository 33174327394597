import {
  Button,
  Card,
  Center,
  Grid,
  Group,
  Image,
  Loader,
  Space,
  Text,
} from "@mantine/core";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { getRecipes } from "../../api/recipe";

export const RecipesPage = () => {
  const [loading, setLoading] = useState(false);
  const [recipes, setRecipes] = useState([]);

  useEffect(() => {
    setLoading(true);
    getRecipes()
      .then((data) => {
        setRecipes(data.recipes);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div>
      <Group position="apart" align="center">
        <Text size={18}>Рецепты</Text>
        <Link to="/recipes/create">
          <Button variant="outline" size="xs">
            Добавить
          </Button>
        </Link>
      </Group>
      <Space h="xl" />
      {loading ? (
        <Center>
          <Space h={100} />
          <Loader />
        </Center>
      ) : (
        <Grid>
          {recipes.map((item) => (
            <Grid.Col key={item.id} md={6} lg={4}>
              <Card radius="md" withBorder>
                <Card.Section>
                  <Image
                    src={`/uploads/${item.images[0]}`}
                    height={160}
                    alt={item.images[0]}
                  />
                </Card.Section>
                <Space h="sm" />
                <Text weight={500}>{item.title}</Text>
                <Text size="sm" color="dimmed" truncate>
                  {item.description}
                </Text>
                <Space h="sm" />
                <Link to={`/recipes/${item.id}`}>
                  <Button variant="outline" size="xs">
                    Изменить
                  </Button>
                </Link>
              </Card>
            </Grid.Col>
          ))}
        </Grid>
      )}
    </div>
  );
};
