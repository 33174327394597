import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Link, Navigate } from "react-router-dom";
import {
  Burger,
  Drawer,
  Header,
  Image,
  Loader,
  Space,
  Text,
} from "@mantine/core";
import styled from "styled-components";

import { userStore } from "../../store/user";
import { IconLogout } from "@tabler/icons-react";

const HEADER_HEIGHT = 70;

export const Layout = observer(({ children }) => {
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    userStore.init();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    window.location.href = "/login";
  };

  if (userStore?.isLoading) {
    return (
      <LoadingView>
        <Loader />
      </LoadingView>
    );
  }

  if (!userStore?.id) {
    return <Navigate to="/login" />;
  }

  const pathname = window.location.pathname;

  const renderLinks = () => {
    return (
      <>
        <Link to="/" onClick={() => setOpened(false)}>
          <NavbarItem active={pathname === "/" ? "true" : undefined}>
            <Text>Тексты</Text>
          </NavbarItem>
        </Link>
        <Link to="/products" onClick={() => setOpened(false)}>
          <NavbarItem active={pathname === "/products" ? "true" : undefined}>
            <Text>Товары</Text>
          </NavbarItem>
        </Link>
        <Link to="/recipes" onClick={() => setOpened(false)}>
          <NavbarItem active={pathname === "/recipes" ? "true" : undefined}>
            <Text>Рецепты</Text>
          </NavbarItem>
        </Link>
        <Link to="/applications" onClick={() => setOpened(false)}>
          <NavbarItem
            active={pathname === "/applications" ? "true" : undefined}
          >
            <Text>Заявки</Text>
          </NavbarItem>
        </Link>
      </>
    );
  };

  return (
    <>
      <Header height={HEADER_HEIGHT}>
        <HeaderContainer>
          <a href="/">
            <Image width={35} height={35} src="/logo.svg" />
          </a>
          <Navbar>
            {renderLinks()}
            <NavbarItem onClick={handleLogout}>
              <IconLogout color="rgb(255, 107, 107)" />
            </NavbarItem>
          </Navbar>
          <MobileNavbar>
            <Burger
              opened={opened}
              onClick={() => setOpened((prev) => !prev)}
              className="burger"
              size="md"
            />
            <Drawer
              opened={opened}
              onClose={() => setOpened(false)}
              position="right"
              size="70%"
            >
              {renderLinks()}
              <Space h="md" />
              <NavbarItem onClick={handleLogout}>
                <Text color="rgb(255, 107, 107)">Выйти</Text>
                <Space w="xs" />
                <IconLogout color="rgb(255, 107, 107)" />
              </NavbarItem>
            </Drawer>
          </MobileNavbar>
        </HeaderContainer>
      </Header>
      <Content>{children}</Content>
    </>
  );
});

const LoadingView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
`;

const HeaderContainer = styled.div`
  height: ${HEADER_HEIGHT}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;

  @media only screen and (max-width: 1032px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const Navbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  @media only screen and (max-width: 500px) {
    display: none;
  }
`;

const NavbarItem = styled.div`
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  color: rgb(73, 80, 87);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 5px;

  ${(props) =>
    props.active
      ? `
        background-color: rgb(231, 245, 255);
        color: rgb(34, 139, 230);
      `
      : `
        &:hover {
          background-color: rgb(248, 249, 250);
        }
  `}
`;

const Content = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 15px;
  padding-bottom: 15px;

  @media only screen and (max-width: 1032px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const MobileNavbar = styled.div`
  display: none;

  @media only screen and (max-width: 500px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
  }
`;
