import { createBrowserRouter } from "react-router-dom";

import { Layout } from "./components";
import {
  ApplicationsPage,
  LoginPage,
  ProductsPage,
  RecipesPage,
  TextsPage,
  CreateProductPage,
  UpdateProductPage,
  CreateRecipePage,
  UpdateRecipePage,
} from "./pages";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <TextsPage />
      </Layout>
    ),
  },
  {
    path: "/products",
    element: (
      <Layout>
        <ProductsPage />
      </Layout>
    ),
  },
  {
    path: "/products/create",
    element: (
      <Layout>
        <CreateProductPage />
      </Layout>
    ),
  },
  {
    path: "/products/:productId",
    element: (
      <Layout>
        <UpdateProductPage />
      </Layout>
    ),
  },
  {
    path: "/recipes",
    element: (
      <Layout>
        <RecipesPage />
      </Layout>
    ),
  },
  {
    path: "/recipes/create",
    element: (
      <Layout>
        <CreateRecipePage />
      </Layout>
    ),
  },
  {
    path: "/recipes/:recipeId",
    element: (
      <Layout>
        <UpdateRecipePage />
      </Layout>
    ),
  },
  {
    path: "/applications",
    element: (
      <Layout>
        <ApplicationsPage />
      </Layout>
    ),
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
]);
