import { makeAutoObservable } from "mobx";
import { getUser } from "../api/user";

class User {
  id = "";
  email = "";
  isLoading = true;

  constructor() {
    makeAutoObservable(this);
  }

  setUser({ id, email }) {
    this.id = id || "";
    this.email = email || "";
  }

  init() {
    this.isLoading = true;
    return getUser()
      .then(({ id, email }) => {
        this.setUser({ id, email });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}

export const userStore = new User();
