import {
  ActionIcon,
  Alert,
  Button,
  Card,
  Center,
  Grid,
  Group,
  Loader,
  Modal,
  Space,
  Text,
  Textarea,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { IconAlertCircle, IconEdit } from "@tabler/icons-react";

import { getTexts, updateText } from "../../api/text";

const TextItem = ({ title, text, type, setTexts }) => {
  const [editing, setEditing] = useState(false);
  const [editingText, setEditingText] = useState(text);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleUpdateText = (e) => {
    e.preventDefault();
    setLoading(true);
    if (error) setError(null);
    updateText({ type, value: editingText })
      .then((data) => {
        setEditing(false);
        setTexts((prev) => {
          const exist = prev.find((item) => item.type === type);
          if (exist) {
            return prev.map((item) => {
              if (item.type === type) {
                return data.text;
              }
              return item;
            });
          } else {
            return [...prev, data.text];
          }
        });
      })
      .catch((err) => {
        console.error(err);
        setError("Произошла ошибка, повторите еще раз");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Grid.Col md={6} lg={6}>
        <Card className="card" padding="md" withBorder>
          <Group align="center" position="apart">
            <Text weight={500}>{title}</Text>
            <ActionIcon onClick={() => setEditing(true)}>
              <IconEdit size={24} color="rgb(34, 139, 230)" />
            </ActionIcon>
          </Group>
          <Text size={14} color="gray" style={{ whiteSpace: "pre-line" }}>
            {text || "Нет текста"}
          </Text>
        </Card>
      </Grid.Col>
      <Modal
        opened={editing}
        onClose={() => setEditing(false)}
        title={`Изменить текст - ${title}`}
        size="xl"
      >
        <form onSubmit={handleUpdateText}>
          <Textarea
            value={editingText}
            onChange={(e) => setEditingText(e.target.value)}
            autosize
            minRows={3}
            placeholder="Введите текст..."
            required
          />
          <Space h="md" />
          {error ? (
            <>
              <Alert icon={<IconAlertCircle size="1rem" />} color="red">
                {error}
              </Alert>
              <Space h="md" />
            </>
          ) : null}
          <Button loading={loading} type="submit">
            Изменить
          </Button>
        </form>
      </Modal>
    </>
  );
};

export const TextsPage = () => {
  const [texts, setTexts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getTexts()
      .then((data) => {
        setTexts(data.texts);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const aboutText = texts.find((item) => item.type === "about");
  const deliveryText = texts.find((item) => item.type === "delivery");
  const paymentText = texts.find((item) => item.type === "payment");
  const cooperationText = texts.find((item) => item.type === "cooperation");

  return (
    <div>
      <Text size={18}>Тексты</Text>
      <Space h="md" />
      {loading ? (
        <Center>
          <Space h={100} />
          <Loader />
        </Center>
      ) : (
        <Grid>
          <TextItem
            title="О нас"
            text={aboutText?.value}
            type="about"
            setTexts={setTexts}
          />
          <TextItem
            title="Доставка"
            text={deliveryText?.value}
            type="delivery"
            setTexts={setTexts}
          />
          <TextItem
            title="Оплата"
            text={paymentText?.value}
            type="payment"
            setTexts={setTexts}
          />
          <TextItem
            title="Сотрудничество"
            text={cooperationText?.value}
            type="cooperation"
            setTexts={setTexts}
          />
        </Grid>
      )}
    </div>
  );
};
