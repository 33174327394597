import axios from "axios";

export const getProducts = () => {
  return axios.get("/products").then((res) => {
    return { products: res?.data?.products || [] };
  });
};

export const getProduct = (id) => {
  return axios.get(`/products/${id}`).then((res) => {
    return { product: res?.data?.product || [] };
  });
};

export const createProduct = ({ name, description, price, images }) => {
  return axios
    .post("/products", { name, description, price, images })
    .then((res) => {
      return { product: res?.data?.product };
    });
};

export const updateProduct = (
  productId,
  { name, description, price, images, isHit }
) => {
  return axios
    .put(`/products/${productId}`, { name, description, price, images, isHit })
    .then((res) => {
      return { product: res?.data?.product };
    });
};

export const deleteProduct = (productId) => {
  return axios.delete(`/products/${productId}`).then((res) => {
    return { product: res?.data?.product };
  });
};
