import "./api";

import React from "react";
import { RouterProvider } from "react-router-dom";

import { router } from "./router";
import { GlobalStyles } from "./components";

function App() {
  return (
    <>
      <RouterProvider router={router} />
      <GlobalStyles />
    </>
  );
}

export default App;
