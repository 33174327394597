import {
  Button,
  Center,
  Group,
  Loader,
  Space,
  Table,
  Text,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { IconStar } from "@tabler/icons-react";

import { getProducts } from "../../api/product";

export const ProductsPage = () => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setLoading(true);
    getProducts()
      .then((data) => {
        setProducts(data?.products || []);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const rows = products.map((element) => (
    <tr key={element.id}>
      <td>
        <Images>
          {element.images.map((item) => (
            <img src={`/uploads/${item}`} key={item} alt={item} />
          ))}
        </Images>
      </td>
      <td className="name">
        <Group align="center" spacing="xs">
          <Link to={`/products/${element.id}`}>{element.name}</Link>
          {element.isHit ? <IconStar color="orange" size={14} /> : null}
        </Group>
      </td>
      <td className="description">{element.description}</td>
      <td className="price">{element.price} ₽</td>
    </tr>
  ));

  return (
    <div>
      <Group position="apart" align="center">
        <Text size={18}>Товары</Text>
        <Link to="/products/create">
          <Button variant="outline" size="xs">
            Добавить
          </Button>
        </Link>
      </Group>
      <Space h="md" />
      {loading ? (
        <Center>
          <Space h={100} />
          <Loader />
        </Center>
      ) : (
        <Content>
          <Table>
            <thead>
              <tr>
                <th></th>
                <th>Название</th>
                <th>Описание</th>
                <th>Цена</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </Content>
      )}
    </div>
  );
};

const Images = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 200px;

  img {
    height: 60px;
  }
`;

const Content = styled.div`
  overflow-x: auto;

  .description {
    max-width: 200px;
  }

  @media only screen and (max-width: 700px) {
    .description {
      min-width: 300px;
    }
    .name {
      min-width: 200px;
    }
    .price {
      min-width: 100px;
    }
  }
`;
