import { Center, Loader, Space, Table, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import moment from "moment";

import { getApplications } from "../../api/application";

export const ApplicationsPage = () => {
  const [loading, setLoading] = useState(false);
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    setLoading(true);
    getApplications({})
      .then((data) => {
        setApplications(data.applications || []);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, []);

  const rows = applications.map((element) => (
    <tr key={element.id}>
      <td>{element.name}</td>
      <td>{element.phone}</td>
      <td>{element.email}</td>
      <td>{moment(element.createdAt).format("DD.MM.YYYY HH:mm")}</td>
    </tr>
  ));

  return (
    <div>
      <Text size={18}>Заявки</Text>
      <Space h="md" />
      {loading ? (
        <Center>
          <Space h={100} />
          <Loader />
        </Center>
      ) : (
        <Content>
          <Table>
            <thead>
              <tr>
                <th>Имя</th>
                <th>Номер</th>
                <th>Email</th>
                <th>Дата</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </Content>
      )}
    </div>
  );
};

const Content = styled.div`
  overflow-x: auto;
`;
