import axios from "axios";

axios.create({
  headers: { "Content-Type": "application/json" },
});

axios.defaults.baseURL = "/api";

axios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (config?.headers && accessToken) {
      config.headers["Authorization"] = accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const refreshAuthLogic = () => {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  if (!accessToken || !refreshToken) {
    return Promise.reject("Tokens are undefined while refresh");
  }
  return fetch("/api/admin/refresh", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ refreshToken }),
  })
    .then((res) => res.json())
    .then((data) => {
      if (!data?.accessToken) {
        return Promise.reject(
          "New refresh tokens are undefined after getting new pair"
        );
      }
      localStorage.setItem("accessToken", data.accessToken);
      if (data.refreshToken) {
        localStorage.setItem("refreshToken", data.refreshToken);
      }
      processQueue(null, data.access_token);
      return data;
    })
    .catch((err) => {
      processQueue(err, "");
      throw err;
    });
};

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const originalRequest = err.config;

    if (err?.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = token;
            return axios(originalRequest);
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        refreshAuthLogic()
          .then((rs) => {
            const newAccessToken = rs?.data?.access_token;
            axios.defaults.headers.common[
              "X-AUTH-TOKEN"
            ] = `Bearer ${newAccessToken}`;
            processQueue(null, newAccessToken);
            resolve(axios(originalRequest));
          })
          .catch((reason) => {
            localStorage.clear();
            processQueue(reason, undefined);
            reject(reason);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(err);
  }
);
