import axios from "axios";

export const getTexts = () => {
  return axios.get("/text").then((res) => {
    return { texts: res.data?.texts || [] };
  });
};

export const updateText = ({ type, value }) => {
  return axios.post("/text", { type, value }).then((res) => {
    return { text: res?.data?.text };
  });
};
