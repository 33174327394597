import axios from "axios";

export const getRecipes = () => {
  return axios.get("/recipes").then((res) => {
    return { recipes: res?.data?.recipes || [] };
  });
};

export const getRecipe = (id) => {
  return axios.get(`/recipes/${id}`).then((res) => {
    return { recipe: res?.data?.recipe || [] };
  });
};

export const createRecipe = ({ title, description, images }) => {
  return axios.post("/recipes", { title, description, images }).then((res) => {
    return { recipe: res?.data?.recipe };
  });
};

export const updateRecipe = (recipeId, { title, description, images }) => {
  return axios
    .put(`/recipes/${recipeId}`, { title, description, images })
    .then((res) => {
      return { recipe: res?.data?.recipe };
    });
};

export const deleteRecipe = (recipeId) => {
  return axios.delete(`/recipes/${recipeId}`).then((res) => {
    return { recipe: res?.data?.recipe };
  });
};
