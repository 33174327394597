import React, { useState } from "react";
import styled from "styled-components";
import {
  Text,
  PasswordInput,
  Space,
  TextInput,
  Button,
  Alert,
} from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";

import { loginUser } from "../../api/user";

export const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = e.target.elements.email.value;
    const password = e.target.elements.password.value;
    if (error) setError(null);
    setLoading(true);
    loginUser({ email, password })
      .then((data) => {
        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("refreshToken", data.refreshToken);
        window.location.href = "/";
      })
      .catch((err) => {
        console.error(err);
        setError("Неправильный email или пароль");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container>
      <Text>Войти в панель администратора</Text>
      <Space h="lg" />
      <Form onSubmit={handleSubmit}>
        <TextInput name="email" label="Email" type="email" required />
        <Space h="md" />
        <PasswordInput name="password" label="Пароль" required />
        <Space h="md" />
        {error ? (
          <>
            <Alert icon={<IconAlertCircle size="1rem" />} color="red">
              {error}
            </Alert>
            <Space h="md" />
          </>
        ) : null}
        <Button loading={loading} type="submit">
          Войти
        </Button>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
`;

const Form = styled.form`
  width: 300px;

  button {
    width: 100%;
  }
`;
